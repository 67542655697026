
import { Route, BrowserRouter, Routes } from 'react-router-dom'

import { Sidebar } from '@northvolt/frontend-components/lib/Sidebar'

import LoginRoute from './routes/login'
import LogoutRoute from './routes/logout'
import NativeRoute from './routes/native'
import NotFoundRoute from './routes/notfound'
import RemoteRoute from './routes/remote'

function App() {
  return (
    <Sidebar footer={`Login ${process.env.REACT_APP_VERSION}`}>
      <BrowserRouter>
        <Routes>
          <Route element={<LoginRoute />} path="/" />
          <Route element={<NativeRoute />} path="/native" />
          <Route element={<LogoutRoute />} path="/logout" />
          <Route element={<RemoteRoute />} path="/remote" />
          <Route element={<NotFoundRoute />} path="/*" />
        </Routes>
      </BrowserRouter>
    </Sidebar>
  )
}

export default App
