export const commonSpellingErrors = [
  'notthvolt.com',
  'nirthbolt.com',
  'norrthvolt.com',
  'norrhvolt.com',
  'northvot.com',
  'northvolt.con',
  'northcloud.com',
  'northvolth.com',
  'norrvolt.com',
  'northvolt.se',
  'nortvolt.com',
  'northvolt.pl',
  'nirthvolt.com',
  'northvolt.co',
  'nirtvolt.com',
  'nothcloud.com',
  'northvoöt.com',
  'northvoöt.se',
  'northcolt.com',
  'northhvolt.com',
  'northvol.com',
  'orthbolt.com',
  'orthvolt.com',
  'northvilt.com',
  'northvolt.ckm',
  'nortgvolt.com',
  'northvolt.cm',
  'gmail.com',
  'live.com',
  'hotmail.com',
  'norrhvolt.vom',
  'northvolvt.com',
  'northvoltcom',
  'nothvolt.com',
  'nprthvolt.xom',
  'northvoly.com',
  'northvolt.clm',
  'northvolt.cpm',
  'NORTHVOLT.COM',
  'nortvolht.com',
  'northcolt.vom',
  'northovlt.com',
  'northoclt.com',
  'northvolt.vom',
  'northolt.com',
  'norhtvolt.com',
  'northvolt,com',
  'nortjvolt.com',
  'northvolt.om',
  'norhvolt.com',
  'northvt.com',
  'nortvolt.pl',
  'noryhvolt.com',
  'noryhvolt.con',
  'northvolt.cpom',
  'nrthvolt.com',
  'northvolr.com',
  'nrothvolt.com',
  'nortnvolt.com',
  'bortyvolt.com',
  'noerthvolt.com',
  'northbolt',
  'nortthvolt.com',
  'nlrthvolt.com',
  'northvol.tocm',
  'northvlt.com',
  'northvolt.ccom',
  'norhtvol.com',
  'northvolt..com',
  'notrhvolt.com',
  'northvlot.com',
  'northvtolt.com',
  'northwolt.com',
]
