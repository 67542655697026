
import { parse } from 'query-string'

import type { ApplicationEnvironment } from '@northvolt/frontend-components'
import { Modal } from '@northvolt/frontend-components'
import { RemoteLogin } from '@northvolt/frontend-components/lib/RemoteLogin'

import { removeRemoteDefault } from '../lib/remoteDefaultHelpers'

export default function RemoteLoginRoute() {
  const params = parse(window.location.search)
  const { application, redirect } = params

  return (
    <Modal>
      <RemoteLogin
        onLogin={() => {
          removeRemoteDefault()
          window.location.replace('/')
        }}
        application={(application as string || 'Northvolt')}
        redirect={redirect as string}
        environment={process.env.REACT_APP_ENVIRONMENT as ApplicationEnvironment}
      />
    </Modal>
  )
}
