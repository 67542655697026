import * as Sentry from '@sentry/browser'
import { createBrowserHistory } from 'history'
import { useState, useEffect } from 'react'

import {
  Center,
  Text,
  Header,
  Loader,
  Space,
  Input,
  Button,
} from '@northvolt/frontend-components'

import { authenticate, login, getUserData } from '../lib/authentication'
import { setRemoteDefault, isRemoteDefault, remotePath } from '../lib/remoteDefaultHelpers'

async function upgradeCookies(idToken: string, refreshToken: string) {
  return fetch(`/upgrade/cookie?idToken=${idToken}&refreshToken=${refreshToken}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    credentials: 'include',
    redirect: 'follow',
  }).then((response) => {
    return response.blob()
  })
}

export const history = createBrowserHistory()
const currentPath = history.location.pathname

export default function AuthenticationRoute() {
  const [isLoading, setLoading] = useState(true)
  const [userEmail, setUserEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<Error>()

  useEffect(() => {
    async function auth() {
      try {
        await authenticate()
      } catch (error) {
        // Route to /remote if this is the default option
        if (currentPath !== remotePath && isRemoteDefault()) {
          return window.location.replace(remotePath)
        }

        setLoading(false)
        //@ts-ignore
        setErrorMessage(error.message)
        Sentry.captureException(error)

        return
      }

      try {
        const user = getUserData()

        if (user) {
          const { idToken, refreshToken } = user

          try {
            await upgradeCookies(idToken, refreshToken)
          } catch (error) {
            Sentry.captureException(error)
          }

          const storedRedirect = window.sessionStorage.getItem('redirect')

          if (storedRedirect) {
            window.sessionStorage.removeItem('redirect')
            window.location.replace(storedRedirect.replace(/[-?]\s*$/, ''))
          } else {
            window.location.replace(
              process.env.REACT_APP_REDIRECT_URL
                ? process.env.REACT_APP_REDIRECT_URL
                : 'https://cloud.northvolt.com'
            )
          }
        } else {
          setLoading(false)
        }

        //
        //
        // if (user) {
        // if (process.env.REACT_APP_LOG_EVERYTHING) {
        //     Sentry.addBreadcrumb({
        //       category: 'auth',
        //       level: Sentry.Severity.Info,
        //       message: 'everything worked',
        //       data: {
        //         user,
        //       },
        //     })
        //     Sentry.captureMessage('login worked', Sentry.Severity.Info)
        //     setTimeout(() => {
        //       window.location.replace(
        //         process.env.REACT_APP_REDIRECT_URL
        //           ? process.env.REACT_APP_REDIRECT_URL
        //           : 'https://cloud.northvolt.com'
        //       )
        //     }, 2000)
        // } else {
        //     const storedRedirect = window.sessionStorage.getItem('redirect')
        //
        //     if (storedRedirect) {
        //       window.sessionStorage.removeItem('redirect')
        //       window.location.replace(storedRedirect)
        //     } else {
        //       window.location.replace(
        //         process.env.REACT_APP_REDIRECT_URL
        //           ? process.env.REACT_APP_REDIRECT_URL
        //           : 'https://cloud.northvolt.com'
        //       )
        //     }
        // }
        // } else {
        // setLoading(false)
        // }
        //
      } catch (error) {
        setLoading(false)
        //@ts-ignore
        setErrorMessage(error.message)
        Sentry.captureException(error)
      }
    }

    auth().catch((error) => {
      Sentry.captureException(error)
    })
  }, [])

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    )
  }

  async function onSubmitLogin() {
    //@ts-ignore
    setErrorMessage(undefined)

    try {
      setLoading(true)
      login(userEmail)
    } catch (error) {
      //@ts-ignore
      setErrorMessage(error.message)
      Sentry.captureException(error)
      setTimeout(() => {
        setLoading(false)
        setUserEmail('')
      }, 250)
    }
  }

  function onHelp() {
    window.open(
      'https://northvolt.atlassian.net/servicedesk/customer/portal/5/group/23/create/58',
      '_blank'
    )
  }

  return (
    <Center>
      <Space type="l" />
      <Header text="Welcome to Northvolt" />
      <Space />
      <Text text="Please log in to access your applications." />
      <Space type="l" />
      <Input
        fillWidth
        type="email"
        placeholder="email@northvolt.com"
        onChange={(email) => setUserEmail(email)}
        onEnter={onSubmitLogin}
      />
      <Space type="s" />
      <Space type="s" />
      <Button fullWidth label="Login" onClick={onSubmitLogin} />
      <>{errorMessage && <Text error text={`${errorMessage}`} />}</>
      <Space type="l" />
      <Button
        type="link"
        label="Remote Login"
        onClick={() => {
          setRemoteDefault()
          window.location.replace('/remote')
        }}
      />
      <Space type="l" />
      <Button type="link" label="I'm unable to log in" onClick={onHelp} />
      <Space type="l" />
    </Center>
  )
}
