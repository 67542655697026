import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'

import { Center, Loader } from '@northvolt/frontend-components'

import { logout } from '../lib/authentication'

export default function LogoutRoute() {
  useEffect(() => {
    try {
      logout()
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [])

  return (
    <Center>
      <Loader />
    </Center>
  )
}
