import { init } from '@sentry/browser'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import type { ApplicationEnvironment } from '@northvolt/frontend-components'
import { App } from '@northvolt/frontend-components/lib/App'

import LoginApp from './App'

if (
  process.env.REACT_APP_ENVIRONMENT !== 'localhost' &&
  process.env.REACT_APP_ENVIRONMENT !== 'local'
) {
  init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    release: `login-${process.env.REACT_APP_ENVIRONMENT}-v${process.env.REACT_APP_VERSION}`,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <StrictMode>
    <App
      name="login"
      version={process.env.REACT_APP_VERSION as string}
      environment={process.env.REACT_APP_ENVIRONMENT as ApplicationEnvironment}
      sentry={process.env.REACT_APP_SENTRY_DSN as string}
      skipAuthentication
      mixpanelToken="2955cbf2065ff1bf74dd83243c90c4b8"
    >
      <LoginApp />
    </App>
  </StrictMode>
)
