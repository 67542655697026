import * as Sentry from '@sentry/browser'
import { parse } from 'query-string'
import { useEffect } from 'react'

import { Center, Loader } from '@northvolt/frontend-components'

import { refreshToken, login, logout, validateRedirectUrl } from '../lib/authentication'

export default function NativeRoute() {
  useEffect(() => {
    async function auth() {
      const params: any = parse(window.location.search)

      if (params) {
        if (params.refreshToken && (params.redirect_url || params.encoded_redirect_url)) {
          let redirectUrl = params.redirect_url

          // is there an encoded url param?
          if (params.encoded_redirect_url) {
            // deprecated function used on purpose, the replacement is not as widely supported yet and needs extra webpack transpiling (which is atob behind the scenes)
            redirectUrl = atob(params.encoded_redirect_url)
          }

          const isValidUrl = validateRedirectUrl(redirectUrl)

          if (!isValidUrl) {
            return
          }

          try {
            await refreshToken(params.refreshToken)
            window.location.replace(redirectUrl)
          } catch (error) {
            Sentry.captureException(error)
          }
        } else if (params.mail) {
          try {
            login(params.mail)
          } catch (error) {
            Sentry.captureException(error)
            logout()
          }
        }
      }
    }

    auth().catch((error) => {
      Sentry.captureException(error)
    })
  }, [])

  return (
    <Center>
      <Loader />
    </Center>
  )
}
